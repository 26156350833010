<template>
  <div class="view pa24">
    <div class="d-flex">
      <div style="">
        <!-- <el-button class="ma mr10 mb10" @click="delAll">批量删除</el-button> -->
        <el-button class="ma" type="primary" @click="centerDialogVisible = true;upDate=1"
        >+ 添加账号
        </el-button
        >
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        @handleSelectionChange="handleSelectionChange"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :currentPage="currentPage"
        :loading="loading"
    >
      <template v-slot:table>
        <el-table-column type="selection"/>
        <el-table-column prop="name" align="center" label="账号名称"/>
        <el-table-column prop="account" align="center" label="账号"/>
        <el-table-column prop="assRoleModel[0].roleName" align="center" label="角色名称"/>
        <el-table-column prop="createTime" align="center" label="创建时间"/>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
                type="text"
                @click="
                viewDetails(scope.row)
              "
            >编辑
            </el-button
            >
            <customPopconfirm
                v-if="scope.row.isMain==1"
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.dataId)"
                title="确认删除？">
              <el-button
                  slot="reference"
                  class="ml10"
                  style="color: #fd634e"
                  type="text"
              >删除
              </el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="添加账号"
        :visible.sync="centerDialogVisible"
        @close="close"
        :modal-append-to-body="false"
        width="30%"
        center
    >
      <el-form
          ref="ruleForm" :model="ruleForm" :rules="rules" label-width="150px"
      >
        <el-form-item prop="accountName" label="账号名称">
          <el-input
              clearable
              v-model="ruleForm.accountName"
              style="width: 100%"
              placeholder="请输入账号"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="title" label="账号">
          <el-input
              clearable
              v-model="ruleForm.title"
              style="width: 100%"
              placeholder="请输入账号"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="头像" prop="headImg">
          <el-upload :action="uploadUrl"
                     list-type="picture-card"
                     :class="{ disabled: uploadDisabled }"
                     :on-remove="handleRemove"
                     :on-success="handleSuccess"
                     :before-upload="beforeAvatarUploadImg"
                     :file-list="fileList"
                     :limit="1">
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
          <p class="fs6">图片上传比例为1:1，支持格式png、jpg、jpeg，大小不超过500k</p>
        </el-form-item>
        <el-form-item prop="password" label="请输入密码">
          <el-input
              clearable
              v-model="ruleForm.password"
              style="width: 100%"
              placeholder="请输入密码"
              maxlength="50"
              show-password
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="passwordZ" label="请再次输入密码">
          <el-input
              clearable
              v-model="ruleForm.passwordZ"
              style="width: 100%"
              placeholder="请再次输入密码"
              maxlength="50"
              show-password
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="role" label="请选择角色">
          <el-select
              clearable
              v-model="ruleForm.role"
              style="width: 100%"
              placeholder="请选择角色"
          >
            <el-option
                v-for="(item) in roleList"
                :key="item.dataId"
                :label="item.roleName"
                :value="item.dataId"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {addAccAcountInfo,queryListRole,queryAcountPage,updateAccAcountInfo,deleteAssAcount} from "@/api/settlement";

export default {
  name: "account",
  components: {
    commonTable,
    customPopconfirm
  },
  data() {
    return {
      centerDialogVisible: false,
      ruleForm: {
        title: "",
        password:"",
        passwordZ:"",
        role:"",
        headImg:"",
        accountName:""
      },
      dialogVisible: false,
      rules: {
        title: [{required: true, message: "请输入账号", trigger: "blur"}],
        password: [{required: true, message: "请输入密码", trigger: "blur"}],
        passwordZ: [{required: true, message: "请再次输入密码", trigger: "blur"}],
        accountName: [{required: true, message: "请输入账号名称", trigger: "blur"}],
      },
      roleList:[],
      tableData: [],
      currentPage: 1,
      loading: false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      upDate: 1,
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      fileList:[],
      dataId:''
    };
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
  },
  mounted() {
    this.queryPage();
    this.getJueSe()
  },
  methods: {
    async getJueSe(){
      let data = {
        associationId: localStorage.getItem("associationId"),
      }
      const result = await queryListRole(data);
      console.log(result)
      this.roleList = result.data
    },
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        accId: localStorage.getItem("associationId"),
        assIsMain: localStorage.getItem("assIsMain"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await queryAcountPage(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        for (let i in list) {
          list[i].createTime = this.renderTime(list[i].createTime)
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSelectionChange(value) {

    },
    viewDetails(row) {
      this.upDate = 2
      this.centerDialogVisible = true
      let data = JSON.parse(JSON.stringify(row));
      console.log(data)
      this.ruleForm.accountName = data.name;
      this.ruleForm.title = data.account;
      this.ruleForm.headImg = data.headImg;
      this.ruleForm.password = data.password;
      this.ruleForm.passwordZ = data.password;
      if ( data.assRoleModel ) {
        this.ruleForm.role = data.assRoleModel[0].dataId;
      }
      this.fileList = [{ url: data.headImg, uid: 1 }];
      this.dataId = data.dataId
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await deleteAssAcount({dataId: val});
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    //搜索
    async filterNames() {
      this.currentPage = 1;
      let data = {
        title: this.filterName
      };
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        password: "",
        passwordZ: "",
        role:"",
        accountName: "",
      }),
          this.centerDialogVisible = false;
      this.fileList = []
    },
    determine() {
      if (this.ruleForm.password != this.ruleForm.passwordZ) {
        this.$message.error('两次输入密码不一致，请重新输入！')
        return
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              accId: localStorage.getItem("associationId"),
              assIsMain: 1,
              account: this.ruleForm.title,
              password: this.ruleForm.password,
              userRoleIds: this.ruleForm.role,
              name: this.ruleForm.accountName,
              headImg: this.ruleForm.headImg,
            };
            this.$message({
              message: "正在保存",
            });
            if (this.upDate == 1) {
              await addAccAcountInfo(data);
            } else {
              data.dataId = this.dataId
              await updateAccAcountInfo(data);
            }
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleRemove(file, fileList) {
      this.fileList = []
      this.ruleForm.headImg = ''
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.headImg = this.ossUrl + file.response.data
      this.fileList = fileList;
    },
    beforeAvatarUploadImg(file){
      const isLt2M = file.size / 1024 / 1024 < 0.5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500KB!");
      }
      return isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped></style>




